* {
  --color-system-purple-purple-1: #f9f0ff;
  --color-system-purple-purple-2: #efdbff;
  --color-system-purple-purple-3: #d3adf7;
  --color-system-purple-purple-4: #b37feb;
  --color-system-purple-purple-8: #36175e;
  --color-system-purple-purple-7: #380974;
  --color-system-purple-purple-10: #120338;
  --color-system-purple-purple-9: #25064c;
  --color-system-purple-purple-5: #9254de;
  --color-system-gray-gray-1: #fff;
  --color-system-gray-gray-2: #fafafa;
  --color-system-gray-gray-3: #f5f5f5;
  --color-system-purple-purple-6: #4e1c95;
  --color-system-gray-gray-4: #f0f0f0;
  --color-system-gray-gray-5: #d9d9d9;
  --color-system-gray-gray-6: #bfbfbf;
  --color-system-gray-gray-7: #8c8c8c;
  --color-system-gray-gray-8: #595959;
  --color-system-gray-gray-10: #262626;
  --color-system-gray-gray-11: #1f1f1f;
  --color-system-gray-gray-9: #434343;
  --color-system-gray-gray-12: #141414;
  --color-system-gray-gray-13: #000;
  --color-system-blue-blue-1: #e6f7ff;
  --color-system-blue-blue-2: #bae7ff;
  --color-system-blue-blue-3: #91d5ff;
  --color-system-blue-blue-4: #69c0ff;
  --color-system-blue-blue-5: #40a9ff;
  --color-system-blue-blue-6: #1890ff;
  --color-system-blue-blue-7: #096dd9;
  --color-system-blue-blue-8: #0050b3;
  --color-system-blue-blue-9: #003a8c;
  --color-system-blue-blue-10: #002766;
  --color-system-green-green-2: #cbf0c7;
  --color-system-green-green-1: #eafbe8;
  --color-system-green-green-4: #74c76a;
  --color-system-green-green-3: #88d77f;
  --color-system-green-green-5: #23a942;
  --color-system-green-green-6: #41a036;
  --color-system-green-green-8: #256c1d;
  --color-system-green-green-7: #378d2e;
  --color-system-green-green-9: #135200;
  --color-system-green-green-10: #092b00;
  --color-system-gold-gold-1: #fffbe6;
  --color-system-gold-gold-2: #fff1b8;
  --color-system-gold-gold-3: #ffe58f;
  --color-system-gold-gold-4: #ffd666;
  --color-system-gold-gold-5: #ffc53d;
  --color-system-gold-gold-6: #faad14;
  --color-system-gold-gold-8: #ad6800;
  --color-system-gold-gold-7: #d48806;
  --color-system-gold-gold-9: #874d00;
  --color-system-red-red-2: #ffccc7;
  --color-system-red-red-1: #fff1f0;
  --color-system-gold-gold-10: #613400;
  --color-system-red-red-3: #ffa39e;
  --color-system-red-red-4: #ff7875;
  --color-system-red-red-6: #f5222d;
  --color-system-red-red-5: #ff4d4f;
  --color-system-red-red-8: #a8071a;
  --color-system-red-red-7: #cf1322;
  --color-system-red-red-9: #820014;
  --color-system-red-red-10: #5c0011;
  --color-system-volcano-volcano-2: #ffd8bf;
  --color-system-volcano-volcano-1: #fff2e8;
  --color-system-volcano-volcano-3: #ffbb96;
  --color-system-volcano-volcano-4: #ff9c6e;
  --color-system-volcano-volcano-5: #ff7a45;
  --color-system-volcano-volcano-6: #fa541c;
  --color-system-volcano-volcano-9: #871400;
  --color-system-volcano-volcano-8: #ad2102;
  --color-system-volcano-volcano-10: #610b00;
  --color-system-volcano-volcano-7: #d4380d;
  --color-system-orange-orange-1: #fff7e6;
  --color-system-orange-orange-2: #ffe7ba;
  --color-system-orange-orange-4: #ffc069;
  --color-system-orange-orange-3: #ffd591;
  --color-system-orange-orange-5: #ffa940;
  --color-system-orange-orange-6: #fa8c16;
  --color-system-orange-orange-7: #d46b08;
  --color-system-orange-orange-8: #ad4e00;
  --color-system-orange-orange-9: #873800;
  --color-system-orange-orange-10: #612500;
  --color-system-yellow-yellow-2: #ffffb8;
  --color-system-yellow-yellow-1: #feffe6;
  --color-system-yellow-yellow-3: #fffb8f;
  --color-system-yellow-yellow-4: #fff566;
  --color-system-yellow-yellow-6: #fadb14;
  --color-system-yellow-yellow-7: #d4b106;
  --color-system-yellow-yellow-8: #ad8b00;
  --color-system-yellow-yellow-5: #ffec3d;
  --color-system-yellow-yellow-9: #876800;
  --color-system-yellow-yellow-10: #614700;
  --color-system-lime-lime-1: #fcffe6;
  --color-system-lime-lime-3: #eaff8f;
  --color-system-lime-lime-2: #f4ffb8;
  --color-system-lime-lime-4: #d3f261;
  --color-system-lime-lime-5: #bae637;
  --color-system-lime-lime-6: #a0d911;
  --color-system-lime-lime-7: #7cb305;
  --color-system-cyan-cyan-2: #b5f5ec;
  --color-system-lime-lime-8: #5b8c00;
  --color-system-lime-lime-9: #3f6600;
  --color-system-cyan-cyan-3: #87e8de;
  --color-system-cyan-cyan-1: #e6fffb;
  --color-system-lime-lime-10: #254000;
  --color-system-cyan-cyan-6: #13c2c2;
  --color-system-cyan-cyan-4: #5cdbd3;
  --color-system-cyan-cyan-5: #36cfc9;
  --color-system-cyan-cyan-9: #00474f;
  --color-system-cyan-cyan-8: #006d75;
  --color-system-cyan-cyan-7: #08979c;
  --color-system-cyan-cyan-10: #002329;
  --color-system-geek-blue-geek-blue-1: #f0f5ff;
  --color-system-geek-blue-geek-blue-2: #d6e4ff;
  --color-system-geek-blue-geek-blue-4: #85a5ff;
  --color-system-geek-blue-geek-blue-3: #adc6ff;
  --color-system-geek-blue-geek-blue-5: #597ef7;
  --color-system-geek-blue-geek-blue-7: #1d39c4;
  --color-system-geek-blue-geek-blue-6: #2f54eb;
  --color-system-geek-blue-geek-blue-8: #10239e;
  --color-system-geek-blue-geek-blue-9: #061178;
  --color-system-geek-blue-geek-blue-10: #030852;
  --color-system-deep-blue-deep-blue-1: #ebeff4;
  --color-system-deep-blue-deep-blue-2: #dfe1ed;
  --color-system-deep-blue-deep-blue-3: #a7adca;
  --color-system-deep-blue-deep-blue-4: #777ea6;
  --color-system-deep-blue-deep-blue-5: #4f5783;
  --color-system-deep-blue-deep-blue-7: #22294f;
  --color-system-deep-blue-deep-blue-6: #373d5f;
  --color-system-deep-blue-deep-blue-8: #1e2447;
  --color-system-deep-blue-deep-blue-10: #111427;
  --color-system-deep-blue-deep-blue-9: #1b203f;
  --color-system-magenta-magenta-2: #ffd6e7;
  --color-system-magenta-magenta-1: #fff0f6;
  --color-system-magenta-magenta-3: #ffadd2;
  --color-system-magenta-magenta-4: #ff85c0;
  --color-system-magenta-magenta-5: #f759ab;
  --color-system-magenta-magenta-8: #9e1068;
  --color-system-magenta-magenta-7: #c41d7f;
  --color-system-magenta-magenta-9: #780650;
  --color-system-magenta-magenta-6: #eb2f96;
  --color-system-magenta-magenta-10: #520339;

  --color-system-gray-gray-14: #f5f5ff;
  --color-system-gray-gray-15: #a2a2ba;
  --color-system-gray-gray-16: #bdbdd3;
  --color-system-gray-gray-17: #17082d;
  --color-system-gray-gray-18: #7b7b97;
  --color-system-gray-gray-19: #e9ecf3;
  --color-system-gray-gray-20: #f9fafb;
  --color-system-gray-gray-21: #afadc8;
  --color-system-gray-gray-22: #f8f8fc;
  --color-system-gray-gray-23: #dbdbed;
  --color-system-gray-gray-24: #e2e6ee;
}
