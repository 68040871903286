@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* @import 'node_modules/slick-carousel/slick/slick'; */
#root {
  height: 100vh;
  overflow-y: hidden;
}

body {
  font-family: Lato, sans-serif;
  background-color: #e5e5e5;
}
button {
  border-color: transparent;
}

.ant-notification-notice-icon {
  font-size: 38px !important;
  display: block !important;
  margin-right: 20px !important;
  padding-right: 20px !important;
}

.ant-notification.ant-notification-topRight {
  right: 0% !important;
  top: 8% !important;
  /* bottom: 0px !important;
  left: 40% !important; */
}
.ant-notification-notice {
  background: #fff;
  border: 2px solid #27a03b;
  box-shadow: 0px 5px #c1c1c1;
}
.ant-notification-notice {
  background: #fff !important;
  color: #000;
}
.ant-notification-notice-message {
  color: #000 !important;
  font-size: 22px !important;
  line-height: 26px;
  font-weight: 600;
  padding-left: 15px;
}
.ant-notification-notice-error .ant-notification-notice-message {
  color: red !important;
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-close-x {
  color: #fff !important;
}
.ant-notification-notice-description {
  font-size: 16px !important;
  line-height: 20px;
  padding-left: 15px;
}

.ant-notification-close-x::before {
  /* content: 'UNDO'; */
  color: #fff !important;
  font-size: 14px;
  margin-right: 5px;
}

.ant-tooltip {
  font-size: 12px !important;
}

/* Radio button ant design start */

.ant-radio-checked .ant-radio-inner {
  border-color: #4e1c95 !important;
}

.table-row-dark {
  background-color: #ffeded;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #4e1c95;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #4e1c95 !important;
}
.ant-notification-notice-description {
  padding-right: 40px;
}
.ant-tabs-tab:hover {
  color: unset !important;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: unset !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-system-purple-purple-6) !important;
  border-color: var(--color-system-purple-purple-6) !important;
}
.ant-select-multiple .ant-select-selection-item-content {
  max-width: 555px;
}

.slick-track {
  min-width: 100%;
}
.downloadbtn {
  background: transparent;
  border: 1px solid #fff;
  padding: 5px 24px;
  line-height: 22px;
  font-size: 12px;
  height: auto;
  line-height: 22px;
  border-radius: 8px;
  right: 80px;
  top: 24px;
  position: absolute;
}
.ril__toolbarRightSide {
  padding-top: 12px;
}
.ril__toolbar {
  height: 70px;
}
.ril__toolbarRightSide {
  padding-top: 6px;
}
.menuButton {
  font-size: 14px;
  background: transparent;
}
.underline {
  text-decoration: underline;
}
.boldPurple {
  font-weight: 600;
  color: var(--color-system-purple-purple-6);
}
.rangeDash::after {
  content: '-';
  position: absolute;
  right: -4px;
  top: 19%;
}
/* Radio button ant design end */

.ant-dropdown-menu-title-content button[disabled] {
  color: var(--color-system-gray-gray-7);
}
.mobileRemoveButton {
  border: 1px solid #382561;
  border-radius: 5px;
  padding: 8px;
}
@media (max-width: 1007px) {
  .ant-select-multiple .ant-select-selection-item-content {
    max-width: 200px;
  }
}

.headerMenuItems {
  text-align: center;
}

.headerMenuItems .ant-dropdown-menu-item {
  font-size: 16px;
  line-height: 19px;
  padding: 12px 25px;
  text-align: center;
  color: #000;
  border-bottom: 2px solid #e7e7e7;
}

.headerMenuItems .ant-dropdown-menu-item:last-child {
  border-bottom: none !important;
}

.footerButton {
  gap: 16px !important;
  justify-content: end;
  /* margin-top: 24px !important; */
}
.footerButtonModal {
  gap: 16px !important;
  justify-content: center;
  margin-top: 24px !important;
}
.centerPosition {
  align-items: center;
  min-height: 100%;
  display: flex;
  padding-bottom: 30px;
  justify-content: center;
}

.videoWrapper {
  position: relative;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
}
.ant-dropdown-menu-item {
  font-weight: 700;
  font-size: 16px;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .direction-xs-column {
    flex-direction: column !important;
  }
  .footerButton {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .footerButton .ant-btn {
    width: 100%;
  }

  .fix-mobile {
    width: 100% !important;
    justify-content: normal !important;
    display: inline-grid !important;
  }
  .fix-mobile .ant-btn {
    width: 100% !important;
  }
  .position-fix {
    top: 60px !important;
  }
  .margin-top {
    margin-top: 30px !important;
  }
  .margin-bottom {
    margin-bottom: 30px !important;
  }
  .margin-top-10 {
    margin-top: 10px !important;
  }
  .margin-top-60 {
    margin-top: 60px !important;
  }
  .ant-modal-content {
    width: 100% !important;
  }
  .data-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .text-align {
    justify-content: left !important;
  }
  .hide-mobile-device {
    display: none;
  }

  .success {
    color: #27a03b;
  }

  .danger {
    color: red;
  }

  .warning {
    color: #ff8a00;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    border-color: unset;
    background: unset;
  }

  .ant-btn-default:hover,
  .ant-btn-default:focus {
    border-color: unset;
    background: unset;
  }
  .Td-fixing {
    min-width: 320px;
  }
  .productdata-mobilefixing {
    /* overflow-x: auto; */
    margin-bottom: 20px;
  }
  .overflow-x-auto {
    overflow-x: auto;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: unset;
    background: unset;
  }
  .text-align-center {
    text-align: center !important;
  }
  .fixTD-mobile .ant-table-thead > tr > th {
    min-width: 200px !important;
    width: 200px !important;
  }
}
@media (max-width: 768px) {
  .buttonMobileView {
    flex-direction: column !important;
    gap: 16px !important;
  }
  .xs-bottom-space {
    margin-bottom: 15px !important;
  }
  .xs-top-space {
    margin-top: 15px !important;
  }
  .text-mobile-fix {
    margin-top: 30px !important;
  }
  .custumerdetails-mobile-fix {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .status-divfixing {
    width: 100% !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .text-xs-left {
    text-align: left !important;
  }
  .xs-remove-btn {
    position: absolute !important;
    top: 50px !important;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }
  .hide-btn {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 24px 0px;
  }
  .ant-drawer-header-close-only {
    padding: 16px 16px;
  }
  .ant-drawer-header-close-only .ant-drawer-header-title {
    justify-content: end;
  }
  .ant-drawer-header-close-only .ant-drawer-header-title .ant-drawer-close {
    margin-right: 0px;
  }
  .possition-fix {
    position: absolute;
    top: 20rem;
  }
  .customer-table {
    overflow-x: auto !important;
  }
  .customer-table .ant-row {
    display: contents !important;
  }
  .inside-customer-table {
    display: -webkit-inline-box !important;
  }
  .min-width-fixing {
    min-width: 300px !important;
  }
}
@media (max-width: 820px) {
  .min-width-fixing {
    min-width: 300px !important;
  }
  .customer-table .ant-row {
    display: contents !important;
  }
  .inside-customer-table {
    display: -webkit-inline-box !important;
    display: inline-box;
  }
}
@media (max-width: 1024px) {
  /* Row column mobile table view start */

  .TableFix-Td .table-header-row,
  .TableFix-Td .table-body-row {
    display: -webkit-inline-box !important;
    display: inline-box;
    flex-flow: row !important;
  }

  .TableFix-Td .table-header-row .ant-col,
  .TableFix-Td .table-body-row .ant-col {
    min-width: 190px !important;
    flex-flow: column !important;
    flex: 1 1 auto !important;
  }
  .TableFix-Td .table-header-row .ant-col:first-child,
  .TableFix-Td .table-body-row .ant-col:first-child {
    min-width: 50px !important;
  }

  /* Row column mobile table view end */
}
@media only screen and (min-width: 768px) and (max-width: 2560px) {
  .hide-full-device {
    display: none !important;
  }
}
