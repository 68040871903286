:root {
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 24px;
  --spacing-6: 28px;
  --spacing-7: 48px;
  --spacing-8: 56px;
  --spacing-9: 64px;
  --spacing-10: 72px;
  --spacing-11: 96px;
}
